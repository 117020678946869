import React, {useState, useRef} from 'react';
import Seo from 'components/seo';
import Layout from 'layouts/zh';
import ScrollBarShowLayout from 'components/ScrollBarShowLayout';
import { postToTask } from 'api/onlineDemo';
import VideoPlayer from 'components/VideoPlayer';
import classnames from 'classnames';
import useLoginDialog from 'hooks/useLoginDialog';
import { saTrackOnlineDemoButtonClick, saTrackOnlineDemoLoginClick } from 'utils/statistics';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.less'

const Retailing = () => {
  const [user, setUser] = useState(null)
  const [showDemoDesc, setShowDemoDesc] = useState(false);
  const [showViewDataDesc, setShowViewDataDesc] = useState(false); 
  const restaurantVideo = useRef<any>(null)
  const footwearVideo = useRef<any>(null)
  const supermarketVideo = useRef<any>(null)
  const automateMarketingVideo = useRef<any>(null)

  const { detailsYaml } = useStaticQuery(graphql`
    query {
      detailsYaml {
         retailing_overall
         overall_catalogo_mb
         top_icon
         finger_matting
         retailing {
          description
          experienceDemo {
            link
            text
          }
          experienceKPI
          keywords
          title
          saTitle
          restaurant
          footwear
          supermarket
          automateMarketing
          viewData {
            link
            text
          }
        }
      }
    }
  `)
  const { retailing, retailing_overall, overall_catalogo_mb, top_icon, finger_matting } = detailsYaml;
    // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackOnlineDemoLoginClick(retailing?.saTitle);
      window.location.href = '/democenter/retailing.html';
    },
  })

  const handleLink = (url, button_name) => {
    saTrackOnlineDemoButtonClick(button_name)
    url && window.open(url);
  }

  const handleToTask = (type: string) => {
    if (!user) {
    //  return saTrackOnlineDemoLoginClick('测试123')
     return showLoginDialog()
    }

    switch (type) {
      case 'experienceDemo':
        
        showViewDataDesc && setShowViewDataDesc(false);
        setShowDemoDesc(!showDemoDesc);
        break;
      case 'experienceKPI':
        postToTask({name: '体验指标拆解工具'});
        handleLink(retailing?.experienceKPI, '体验指标拆解工具')
        break;
      case 'viewData':
        showDemoDesc && setShowDemoDesc(false)
        setShowViewDataDesc(!showViewDataDesc);
        break
      case 'automateMarketing':
        // @ts-ignore
        automateMarketingVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      case 'restaurant':
        // @ts-ignore
        restaurantVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      case 'supermarket':
        // @ts-ignore
        supermarketVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      case 'footwear':
        // @ts-ignore
        footwearVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      default:
    }
  }

  const renderRetailing = (<div className={styles.retailing}>
    <img width='1920px' src={retailing_overall} />
    <VideoPlayer
        videoOpt={{
          sources: retailing?.restaurant,
        }}
        fuRef={restaurantVideo}
    />

    <VideoPlayer
        videoOpt={{
          sources: retailing?.footwear,
        }}
        fuRef={footwearVideo}
      />
   
     <VideoPlayer
        videoOpt={{
          sources: retailing?.supermarket,
        }}
        fuRef={supermarketVideo}
      />
     <VideoPlayer
        videoOpt={{
          sources: retailing?.automateMarketing,
        }}
        fuRef={automateMarketingVideo}
      />
    <div className={classnames(styles.operationArea)}>
      <div className={styles.descContent}>
        {
          showDemoDesc && (<div className={classnames(styles.descBg, styles.experiencedemo_pd)} style={{ backgroundImage: `url(${top_icon})` }}>
          <ul className={styles.content_experiencedemo}>
            {retailing?.experienceDemo?.map(item => (
              <li key={item?.link} onClick={() => {
                if (!item?.link) {
                  return
                }
                postToTask({ name: '体验行业Demo' });
                handleLink(item?.link, '体验行业Demo');
              }}>
                <span className={styles.contentHover}>
                  {item?.text}
                </span>
              </li>
            ))}
          </ul>
        </div>)
        }

        {
          showViewDataDesc && (<div className={classnames(styles.descBg, styles.viewdata_pd)} style={{ backgroundImage: `url(${top_icon})` }}>
          <ul className={styles.content_viewdata}>
            {retailing?.viewData?.map(item => (
              <li key={item?.link} onClick={() => {
                if (!item?.link) {
                  return
                }
                postToTask({ name: '查看干货资料' });
                handleLink(item?.link, '查看干货资料');
              }}>
                <span className={styles.contentHover}>
                  {item?.text}
                </span>
              </li>
            ))}
          </ul>
        </div>)
        }
      </div>
      
      
      <div className={classnames(styles.clickArea, styles.experienceDemo)} onClick={() => {
        handleToTask('experienceDemo')
      }} />
      <div className={classnames(styles.clickArea, styles.experienceKPI)} onClick={() => handleToTask('experienceKPI')} />
      <div className={classnames(styles.clickArea, styles.viewData)} onClick={() => handleToTask('viewData')}/>
      <div className={classnames(styles.clickArea, styles.watchVideo)} onClick={() => handleToTask('automateMarketing')}/>
    </div>

    <div className={classnames(styles.watchVideoArea, styles.restaurant)} onClick={() => handleToTask('restaurant')}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>
    <div className={classnames(styles.watchVideoArea, styles.footwear)} onClick={() => handleToTask('footwear')}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>
    <div className={classnames(styles.watchVideoArea, styles.supermarket)} onClick={() => handleToTask('supermarket')}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>
  </div>)
  return (
    <Layout showFloat={false} showCookieAuth={false} hideFooter={true}>
      <Seo
        title={retailing?.title}
        description={retailing?.descriptions}
        keywords={retailing?.keywords}
        saTitle={retailing?.saTitle}
      />
      <div className={classnames('block lg:hidden')}>
        <img width='100%' src={overall_catalogo_mb} />
      </div>
      <ScrollBarShowLayout className={classnames('hidden lg:block')} limitWidth={1920} innerH={937} childrenEle={renderRetailing} getUser={(user) => setUser(user)}/>
    </Layout> 
  )
}

export default Retailing;