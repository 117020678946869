// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--tzD+k";
export var contentHover = "index-module--contentHover--9lj0O";
export var content_experiencedemo = "index-module--content_experiencedemo--+F2VF";
export var content_viewdata = "index-module--content_viewdata--AnoRw";
export var descBg = "index-module--descBg--DYgnc";
export var descContent = "index-module--descContent--6zmMx";
export var experiencedemo_pd = "index-module--experiencedemo_pd--Km6sx";
export var footwear = "index-module--footwear--KsACf";
export var guidance = "index-module--guidance--G+rDY";
export var guidanceLogo = "index-module--guidanceLogo--jsugA";
export var operationArea = "index-module--operationArea--Sjvb-";
export var restaurant = "index-module--restaurant--+2jOk";
export var retailing = "index-module--retailing---I-wa";
export var supermarket = "index-module--supermarket--BrqvS";
export var viewdata_pd = "index-module--viewdata_pd--MzftZ";
export var watchVideoArea = "index-module--watchVideoArea--QQFTB";